<!-- eslint-disable ts/no-redeclare -->
<script setup lang="ts">
import type { FormError, FormErrorEvent, FormSubmitEvent } from '#ui/types'
import { destr } from 'destr'

const props = defineProps<{
  state?: any
  columns?: any
  params?: any
  loading?: boolean
  isemit?: boolean
  acceptCheckbox?: boolean
  acceptCheckboxLabel?: string
  iscart?: boolean
  successTitle?: string
  successDescription?: string
  submitLabel?: string
  submitUri?: string
  isforgotpassword?: boolean
  isresetpassword?: boolean
  isregister?: boolean
  islogin?: boolean
  isauth?: boolean
  noresetform?: boolean
  siteType?: any
  paymentMethods?: any
}>()

const emit = defineEmits(['save', 'change', 'ok'])

const config = useRuntimeConfig()

const { t, locale } = useI18n()

const localePath = useLocalePath()

const route = useRoute()

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const host = await useGetHost('host')

const headers: any = { 'x-app-domain': host, 'x-app-locale': locale.value, 'x-app-slug': slug.value }

const token: any = useCookie('token')

if (token.value && props?.isauth) {
  _set(headers, 'Accept', 'application/json')
  _set(headers, 'Authorization', `Bearer ${token.value}`)
}

const submitUri = computed(() => `/api/${props?.submitUri ?? (props?.isauth ? 'qxsxpodrde' : 'fcryqxvxtz')}`)

const columns = computed(() => props?.columns?.length ? props?.columns?.filter((x: any) => _has(x, 'creatable') ? x?.creatable : true) : [])

const state = ref<any>(!_isEmpty(props?.state) ? props?.state : {})
const selected = ref([])
const selected2 = ref([])
const loading = ref(false)
const success = ref(false)
const backendErrors = ref('')

const cartKey = props?.siteType === 4 ? 'cart-shop' : 'cart'

const cartStorageKey = computed(() => locale.value ? `${cartKey}-${locale.value}` : cartKey)

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey.value)) : {}

const cart: any = ref(cartStorage ?? {})

if (props?.iscart)
  _set(state.value, 'payment_method', cart.value?.payment_method ?? 1)

watch(state.value, () => {
  if (import.meta.client)
    onChange()
})

const validateColumns = computed(() => props?.columns?.length ? props?.columns?.filter((x: any) => _has(x, 'validate')) : [])

// function validate(state: any): FormError[] {
function validate(state: any): FormError[] {
  const errors: any = []

  validateColumns.value.forEach((item: any) => {
    const val = _get(state, item?.name)

    if (item?.validate.includes('required') && !val)
      errors.push({ path: item?.name, message: t('required') })

    const confirmedSourceVal = _get(state, _replace(item?.name, '_confirmation', ''))

    if (item?.validate.includes('confirmed') && (!confirmedSourceVal || (val && confirmedSourceVal && val !== confirmedSourceVal)))
      errors.push({ path: item?.name, message: t('confirmed') })
  })

  return errors
}

async function onSave(payload: any) {
  loading.value = true

  if (!_isEmpty(props?.params))
    Object.assign(payload, props?.params)

  const data: any = await $fetch(
    submitUri.value,
    {
      baseURL: config.public.apiBase,
      method: 'POST',
      headers,
      body: payload,
      // mode: 'cors',
      // credentials: 'include',
    },
  ).catch(() => {
    token.value = null
  })

  loading.value = false

  if (!props?.noresetform) {
    state.value = {}
    selected.value = []
    selected2.value = []
    success.value = false
  }

  if (data?.err) {
    backendErrors.value = data?.err
    return false
  }

  success.value = true

  emit('ok', 1)

  return false
}

async function onSubmit(event: FormSubmitEvent<any>) {
  // Do something with data
  // console.log(event.data)
  // console.log(state.value)
  if (props?.isemit)
    emit('save', event.data)

  else
    await onSave(event.data)

  return false
}

async function onError(event: FormErrorEvent) {
  const element = document.getElementById(event.errors[0].id)
  element?.focus()
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

async function onChange() {
  if (props?.isemit)
    emit('change', state.value)
}

async function onChangePayment(val: any) {
  _set(state.value, 'payment_method', val)
  onChange()
}
</script>

<template>
  <UForm
    :validate="validate"
    :state="state"
    class="mx-auto w-full border-gray-200 py-8 px-3 md:px-10"
    @change="onChange"
    @submit="onSubmit"
    @error="onError"
  >
    <slot name="title" />

    <UFormGroup
      v-for="item in columns"
      :key="`sVvviL_${item?.name}`"
      :label="_get(item, `label_${locale}`) ? _get(item, `label_${locale}`) : item?.label"
      :name="item?.name"
      :required="!!item?.validate?.includes('required')"
      size="xl"
      :ui="{
        wrapper: 'mb-4',
        label: {
          base: 'text-lg',
        },
      }"
    >
      <UInput
        v-if="item?.type === 'email'"
        v-model="state[item?.name]"
        :type="item?.type"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        color="white"
        variant="outline"
        autocomplete="false"
      />
      <UTextarea
        v-else-if="item?.type === 'text' || item?.type === 'textarea'"
        v-model="state[item?.name]"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        color="white"
        variant="outline"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && !item?.multiple && !item?.mapoptions"
        v-model="state[item?.name]"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        variant="outline"
        :ui="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && !item?.multiple && item?.mapoptions"
        v-model="state[item?.name]"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        value-attribute="value"
        option-attribute="label"
        variant="outline"
        :ui="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
      >
        <template #label>
          {{ item?.options.find((v: any) => v.value === state[item?.name])?.label ?? t('select') }}
        </template>
      </USelectMenu>
      <USelectMenu
        v-else-if="item?.type === 'select' && item?.multiple && !item?.mapoptions"
        v-model="selected"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        multiple
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        variant="outline"
        :ui="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(val: any) => state[item?.name] = val"
      />
      <USelectMenu
        v-else-if="item?.type === 'select' && item?.multiple && item?.mapoptions"
        v-model="selected2"
        :options="_get(item, `options_${locale}`) ? _get(item, `options_${locale}`) : item?.options"
        multiple
        :searchable="item?.options?.length > 10 ? true : false"
        :searchable-placeholder="t('search')"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        value-attribute="value"
        option-attribute="label"
        variant="outline"
        :ui="{
          input: 'text-lg',
          option: {
            size: 'text-lg',
          },
        }"
        @change="(val: any) => state[item?.name] = val"
      />
      <UToggle
        v-else-if="item?.type === 'toggle'"
        v-model="state[item?.name]"
        on-icon="i-heroicons-check-20-solid"
        off-icon="i-heroicons-x-mark-20-solid"
      />
      <UInput
        v-else-if="item?.type === 'datetime'"
        v-model="state[item?.name]"
        type="datetime-local"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
      />
      <UInput
        v-else-if="item?.type === 'pin'"
        v-model="state[item?.name]"
        type="password"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
      />
      <UInput
        v-else-if="item?.type !== 'string'"
        v-model="state[item?.name]"
        :type="item?.type"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        :step="(item?.type === 'number' && _has(item, 'step')) ? item?.step : 1"
        color="white"
        variant="outline"
      />
      <UInput
        v-else
        v-model="state[item?.name]"
        :placeholder="_get(item, `placeholder_${locale}`) ? _get(item, `placeholder_${locale}`) : item?.placeholder"
        :icon="item?.icon"
        :trailing="false"
        :min="_has(item, 'min') ? item?.min : false"
        :max="_has(item, 'max') ? item?.max : false"
        color="white"
        variant="outline"
        autocomplete="false"
      />
    </UFormGroup>

    <!-- I accept the terms of use and privacy policy v-if="props?.acceptCheckbox" -->
    <UCheckbox
      v-if="!props?.islogin"
      :label="props?.acceptCheckboxLabel || t('accept_terms')"
      required
      :ui="{
        wrapper: 'my-8 items-center',
        base: 'h-6 w-6',
        label: 'text-lg',
      }"
    />

    <UAlert
      v-if="success"
      :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
      icon="i-heroicons-check-circle-solid"
      color="primary"
      variant="soft"
      :title="props?.successTitle || t('submitted')"
      :description="props?.successDescription ?? ''"
      :ui="{
        wrapper: 'my-5',
        title: 'text-lg mb-1',
        description: 'text-lg',
        icon: {
          base: 'h-12 w-12',
        },
      }"
      @close="success = false"
    />
    <UAlert
      v-else-if="backendErrors"
      :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
      icon="i-heroicons-x-circle-solid"
      color="red"
      variant="soft"
      :title="t('fields_required')"
      :ui="{
        wrapper: 'my-5',
        title: 'text-lg mb-1',
        description: 'text-lg',
        icon: {
          base: 'h-12 w-12',
        },
      }"
      @close="backendErrors = ''"
    >
      <template #description>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="backendErrors" />
      </template>
    </UAlert>

    <slot name="balance" />

    <PaymentMethod
      v-if="props?.iscart"
      :payment-methods="props?.paymentMethods"
      @change="onChangePayment"
    />

    <div class="my-10 text-left flex items-center justify-between">
      <UButton
        type="submit"
        :loading="loading || props?.loading"
        :disabled="_isEmpty(state)"
        :label="props?.submitLabel ?? t('send')"
        color="blue"
        size="xl"
        class="rounded-xl px-10 py-3"
      >
        <template #trailing>
          <UIcon name="i-heroicons-arrow-right-20-solid" />
        </template>
      </UButton>

      <div v-if="props?.isresetpassword || props?.isforgotpassword" class="my-5 mx-3">
        <ULink
          :to="localePath('/login')"
          active-class="text-blue"
          inactive-class="text-blue-500 hover:text-blue-700"
          class="font-semibold"
        >
          {{ t('login') }}
        </ULink>
      </div>

      <div v-if="props?.islogin" class="my-5 mx-3">
        <ULink
          :to="localePath('/forgot-password')"
          active-class="text-blue"
          inactive-class="text-blue-500 hover:text-blue-700"
          class="font-semibold"
        >
          {{ t('forgot_password') }}
        </ULink>
      </div>
    </div>

    <div v-if="props?.isregister" class="my-5">
      <ULink
        :to="localePath('/login')"
        active-class="text-blue"
        inactive-class="text-blue-500 hover:text-blue-700"
        class="font-semibold"
      >
        {{ t('login') }}
      </ULink> {{ t('if_have_account') }}
    </div>

    <div v-if="props?.islogin" class="my-5">
      <ULink
        :to="localePath('/register')"
        active-class="text-blue"
        inactive-class="text-blue-500 hover:text-blue-700"
        class="font-semibold"
      >
        {{ t('register') }}
      </ULink> {{ t('if_have_not_account') }}
    </div>
  </UForm>
</template>
